import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import helpers from "../../scripts/helpers"
import WhoAreWe from "../../components/who-are-we"
import Sponsores from "../../components/sponsores"
class SherBrooke extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
                    
            <div className="hero hero--smallest hero--project-today-software-magazine inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center">
                    College SHERBROOKE.
                </h2>
            </div>

            <article className="article project inuit-u-p0">

                <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="http://www.todaysoftmag.com/" title="See Today Software Magazine!" target="_blank">
                                <img src="/img/clients/logo-academy.png" alt="" className="project__logo" />
                            </a>
                        </p>

                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">SHERBROOKE.</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link" href="http://www.collegesherbrooke.com/" title="See Today Software Magazine!"
                                target="_blank">collegesherbrooke.com</a>
                            </span>
                            <span className="project__detail">Year 2016</span>
                        </p>
                    </aside>

                    <h1 className="article__heading">Campagne Back To School.</h1>
                    <p className="article__para">
                        L'entrée scolaire au College Sherbrooke a été couronné de succès avec une campagne a la performance très réussie sur le réseau search et display de Google. 
                    </p>
                    <p className="article__para">
                        <span className="project__detail project__detail--small">Content Creation, Interactive Landing Pages, User Experience Design, Website Design, Digital Marketing, RTB</span>
                    </p>
                </div>

                <div className="article__body">
                    <p className="article__para">
                        
                    </p>
                    <h2 className="article__heading">LANDING PAGES</h2>

                    <p className="article__para">
                        Nous avons commencer par concevoir des Landing Pages modernes, responsives et orientés conversion pour servire de page d’atterrissage répondant à une recherche particulière et ciblée.<br />
                        Le but étant de convertir la visite des jeunes lauréat en un résultat significatif pour l'universit de Sherbrooke, les Landing Pages jouent un role clé dans le cadre des campagnes à la performance.<br />
                    </p>
                    
                </div>
                <p className="article__belt inuit-u-m0">
                    <video className="article__video article__video--play-on-scroll" autoPlay loop muted>
                        <source src="/video/web-design.mp4" type="video/mp4" />
                    </video>
                </p>

                <div className="article__body">
                    <p className="article__para">
                        
                    </p>
                    <h2 className="article__heading">MOBILE : Le contexte d’utilisation bouleverse la donne</h2>

                    <p className="article__para">

                        Sur le mobile, le temps est raccourci, les intentions sont précises, les résultats doivent être rapides. Les dispositifs en ligne sur les mobiles doivent être pensés de manière différente de ceux conçus pour l’ordinateur. Ce n’est pas qu’une question d’affichage. Les attentes sont également différentes.<br />
                        Le trafic généré par l’Internet mobile a récemment dépassé le trafic généré par l’Internet fixe. Pour tirer parti de cette évolution nous avons adopté l'approche mobile first.<br />
                    </p>
                    
                </div>


                <p className="article__belt inuit-u-m0">
                    <video className="article__video article__video--play-on-scroll" autoPlay loop muted>
                        <source src="/video/web-design-second.mp4" type="video/mp4" />
                    </video>
                </p>

                

                <div className="article__body">
                    <p className="article__para">
                        
                    </p>
                    <h2 className="article__heading">SEA - Publicité sur le Réseau Search et Display de Google</h2>
                    
                </div>
                
                <p data-parallax-speed="0.20" className="parallax parallax--horizontal article__belt inuit-u-mb0">
                    <img className="article__belt-image parallax__bg" src="/img/projects/sherbrooke/web-design-background.png"
                        alt="mobile-apps" />
                    <img className="article__belt-image parallax__layer parallax__layer--1"
                        src="/img/projects/sherbrooke/mobile-apps-first-fourth.png" alt="mobile-apps" />
                    <img className="article__belt-image parallax__layer parallax__layer--2"
                        src="/img/projects/sherbrooke/responsive-design-second-fourth.png" alt="mobile-apps" />
                </p>

            </article>
            <Sponsores />
            <WhoAreWe />

        </Layout>
    
  )

  openPopUp = () => {
    window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600')
    return false
  }

  componentDidMount = () => {
    helpers.runParallaxEffect()
  }


}

export default SherBrooke
